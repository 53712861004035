import { NavbarActionTypes } from "../actionTypes/navbarActionTypes";

const initialState = {
    navbarElementsForAdmins: {
        elemenets: [
            { id: 1, order: 1, name: "notifications", icon: "fa-bell", link: "notices" },
            { id: 2, order: 2, name: "deadlines", icon: "fa-calendar-times-o", link: "rgb" },
            { id: 3, order: 3, name: "my_domains", icon: "fa-globe", link: "domains" },
            { id: 4, order: 4, name: "out_domains", icon: "fa-arrow-circle-right", link: "transfer" },
            { id: 5, order: 5, name: "dnssec_settings", icon: "fa-list", link: "listdsec" },
            { id: 6, order: 6, name: "hosting", icon: "fa-server", link: "hostings" },
            { id: 7, order: 7, name: "ssl", icon: "fa-shield", link: "certificates" },
            { id: 8, order: 8, name: "contracts", icon: "fa-files-o", link: "contracts" },
            { id: 9, order: 9, name: "payments", icon: "fa-credit-card-alt", link: "payments" },
            { id: 10, order: 10, name: "files", icon: "fa-file-text-o", link: "files" },
            { id: 11, order: 11, name: "my_contacts", icon: "fa-address-book-o", link: "contacts" },
            { id: 12, order: 12, name: "settings", icon: "fa-cog", link: "users/details" },
            { id: 13, order: 13, name: "last_actions", icon: "fa-clock-o", link: "historys" },
            { id: 14, order: 14, name: "write_offs", icon: "fa-minus-circle", link: "withdraws" },
            { id: 15, order: 15, name: "my_users", icon: "fa-users", link: "users" },
            { id: 16, order: 16, name: "invoices", icon: "fa-file-text-o", link: "invoicesources" },
            { id: 17, order: 17, name: "hosting_resellers", icon: "fa-handshake-o", link: "whmcompanys" },
            { id: 18, order: 18, name: "world_domains", icon: "fa-globe", link: "extdomains" },
            { id: 19, order: 19, name: "dedicated_servers", icon: "fa-server", link: "vpses" },
            { id: 20, order: 20, name: "external_data", icon: "fa-info-circle", link: "external" },
            { id: 21, order: 21, name: "my_requests", icon: "fa-check-circle-o", link: "domainrequests" },
            { id: 22, order: 22, name: "recovery_site", icon: "fa-retweet", link: "restorehostings" },
            { id: 23, order: 23, name: "lots", icon: "fa-exchange", link: "listlots" },
            { id: 24, order: 24, name: "additional_contracts", icon: "fa-files-o", link: "extcontracts" },
            { id: 25, order: 25, name: "whm_hosting", icon: "fa-id-card-o", link: "whmaccounts" },
            { id: 26, order: 26, name: "created_sites", icon: "fa-sitemap", link: "sites" },
            { id: 27, order: 27, name: "fast_services_nav", icon: "fa-list-alt", link: "fast-services-list" },
            { id: 28, order: 28, name: "declorations_list", icon: "fa-file", link: "declorations" },
        ]
    }
};

export const navbarElementsForAdminsReducer = (state = initialState.navbarElementsForAdmins, { type, payload }) => {
    switch (type) {
        case NavbarActionTypes.MOVE_NAVBAR_ELEMENTS:
            return { ...state, elemenets: payload }
        default:
            return state
    }
};